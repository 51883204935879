<template>
  <div class="pharmacist">
    <div class="console">
      <div class="title">工作台</div>
      <div class="main">
        <div>
          <img src="@/assets/image/img_Onlineinquiry@2x.png" alt />
          <div class="title">线上问诊</div>
          <div class="content" @click="seekHandler(1)">
            <span>{{ getWaitNum }} 人待咨询</span>
            <router-link to>点击咨询>></router-link>
          </div>
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible1"
            width="25%"
            :before-close="handleClose"
          >
            <span>该咨询为: {{ chat_type_text }}，是否立即咨询</span>
            <span slot="footer" class="dialog-footer button">
              <el-button @click="sendState(false)">拒 绝</el-button>
              <el-button type="primary" @click="sendState(true)">确 定</el-button>
            </span>
          </el-dialog>
          <!-- 如果拒绝，则显示拒绝原因窗口 -->
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible2"
            width="25%"
            :before-close="handleClose"
          >
            <div class="refuse">
              <span>请选择拒绝原因</span>
              <p>
                <select v-model="reason">
                  <option v-for="(item, i) in refuseReason" :key="i">{{ item.content }}</option>
                </select>
              </p>
            </div>
            <span slot="footer" class="dialog-footer button">
              <el-button @click="sendReason(false)">取消</el-button>
              <el-button type="primary" @click="sendReason(true)">确 定</el-button>
            </span>
          </el-dialog>
        </div>
        <div>
          <img src="@/assets/image/img_Pharmacisttrial@2x.png" alt />
          <div class="title">修改处方</div>
          <div class="content" @click="changeHandler(1)">
            <span>{{ update_pre_num }} 个待审方</span>
            <router-link to>点击查看>></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { getReason } from "@/request/personnel";
import { mapGetters } from "vuex";
export default {
  props: ["update_pre_num", "chat_type"],
  data() {
    return {
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      refuseReason: [], // 问诊拒绝原因
      reason: "",
      chat_type_text: "图文咨询"
    };
  },
  created() {
    getReason({
      token: this.$token
    }).then(res => {
      this.refuseReason = res.data;
      if (res.data[0]) this.reason = res.data[0].content;
    });
  },
  computed: {
    ...mapGetters({ getWaitNum: "getWaitNum" })
  },
  methods: {
    seekHandler(e) {
      if (e == 1 && this.getWaitNum == 0) {
        this.$message.success("目前暂无问诊患者！");
        return;
      }
      this["dialogVisible" + e] = true;
      if (this.chat_type == 1) {
        this.chat_type_text = "视频咨询";
      } else {
        this.chat_type_text = "图文咨询";
      }
    },
    handleClose(done) {
      done();
      document.body.style.overflow = "auto";
    },
    changeHandler(num) {
      this.$emit("changeState", num);
    },
    sendState(e) {
      if (!e) {
        // 拒绝问诊
        this.dialogVisible1 = false;
        this.dialogVisible2 = true;
        document.body.style.overflow = "hidden";
      } else {
        // 接受问诊
        this.dialogVisible1 = false;
        this.$emit("changeOnline", true);
      }
    },
    sendReason(e) {
      this.dialogVisible1 = true;
      this.dialogVisible2 = false;
      if (e) {
        this.dialogVisible1 = false;
        this.$emit("changeOnline", false, this.reason);
      }
      document.body.style.overflow = "auto";
    },
    downLine(e) {
      this.dialogVisible3 = false;
      if (e) {
        let down = {
          method: "doctor_down"
        };
        this.$store.state.sendMessage &&
          this.$store.state.sendMessage(JSON.stringify(down));
      }
    }
  }
};
</script>

<style lang="less" scoped>
.console {
  width: 1000px;
  margin: 0 auto;
  color: #333333;
  font-size: 20px;
}
.console > .title.title {
  font-weight: 500;
  margin-top: 30px;
}
.main {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  height: 100px;
  margin: 50px 0px 50px 0px;
}

.main .title {
  font-size: 18px;
  font-weight: 500;
}
.main > div {
  width: 333px;
}
.main img {
  height: 50px;
  float: left;
  margin-right: 15px;
}
.main .title {
  font-family: MicrosoftYaHei;
  color: #71c0d6;
  font-size: 20px;
  font-weight: 500;
  margin-top: -5px;
  cursor: pointer;
}
.pharmacist .main .content {
  margin-top: 10px;
  color: #656565;
  cursor: pointer;
  font-size: 12px;
  span {
    margin-right: 8px;
  }
}
a {
  color: #71c0d6;
  font-size: 12px;
}
// 弹出框操作
.el-dialog .refuse {
  text-align: center;
  span {
    font-size: 22px;
    font-weight: 400;
  }
  select {
    margin-top: 20px;
    width: 70%;
    height: 22px;
    outline: none;
  }
}
.el-dialog .el-dialog__footer button {
  width: 80px;
  font-weight: 500;
}
.el-dialog__footer button:nth-child(1) {
  background: #fff;
  border: 1px solid #f00000;
  color: #f00000;
}
.el-dialog__footer button:nth-child(2) {
  background: #f00000;
  border: 0px;
  color: #fff;
}
</style> 