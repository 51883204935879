<template>
  <div class="modifyPre" v-if="disInfo">
    <div class="info">
      <div class="title">
        <span>患者信息</span>
        <hr />
      </div>
      <div class="content">
        <table>
          <tr>
            <td>
              <span>姓名</span>
              <span>{{ disInfo.inquiry_name }}</span>
            </td>
            <td>
              <span>性别</span>
              <span>{{ disInfo.inquiry_info.sex === 0 ? "保密" : (disInfo.inquiry_info.sex === 1 ? "男" : "女") }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>年龄</span>
              <span>{{ disInfo.inquiry_info.age }}</span>
            </td>
            <td>
              <span>联系电话</span>
              <span>{{ disInfo.inquiry_info.mobile }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>过敏史</span>
              <span>{{ disInfo.inquiry_info.allergy }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>遗传史</span>
              <span>{{ disInfo.inquiry_info.genetic_disease }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="result">
      <div class="title">
        <span>诊断结果</span>
        <hr />
      </div>
      <div class="content">
        <textarea v-model="disInfo.inquiry_info.diagnosis"></textarea>
      </div>
    </div>
    <div class="addDrug">
      <div class="title">
        <span>添加药品</span>
        <hr />
      </div>
      <div class="drugInfo">
        <div>
          <span>药品信息</span>
        </div>
        <div>
          <span>数量</span>
        </div>
        <div>
          <span>操作</span>
        </div>
      </div>
      <div class="content" v-for="(good, i) in addGoods" :key="i">
        <div class="info">
          <dl>
            <dd>
              <img :src="good.goods_img" alt />
              <p>{{ good.goods_name }}</p>
            </dd>
            <dd>
              <span>用法</span>
              <input type="text" placeholder="请输入药品用法" v-model="good.yongfa" />
            </dd>
            <dd>
              <span>用量</span>
              <input type="text" placeholder="请输入药品用量" v-model="good.yongliang" />
            </dd>
          </dl>
        </div>
        <div class="num">
          <el-input-number v-model="good.num" :min="1" :max="100" @change="handleChange"></el-input-number>
        </div>
        <div class="del">
          <span @click="cancelGood(good)">删除</span>
        </div>
      </div>
    </div>
    <div class="onlineDrug">
      <img src="@/assets/image/Onlinedrugselection_plus@2x.png" alt />
      <span @click="open('addGoodDialog')">在线选药</span>
    </div>
    <div class="autograph">
      <div class="title">
        <span>医生签名</span>
        <hr />
      </div>
      <template v-if="isAutograph">
        <img :src="imgUrl" alt />
      </template>
      <template v-else>
        <div class="content">
          <p @click="open('showGraph')">点击签名</p>
        </div>
      </template>
    </div>
    <div class="send">
      <button @click="sendPharmacistExamine">发送至药师审核</button>
    </div>
    <div @mousewheel.prevent v-show="isShow" class="Mask">
      <!-- 添加商品 -->
      <div v-show="addGoodDialog" class="addGoodDialog">
        <div class="sel">
          <span>添加商品</span>
          <img
            src="@/assets/image/Interrogation_Drugs_delete@2x.png"
            @click="close('addGoodDialog')"
          />
        </div>
        <div class="content" v-if="drugItems">
          <div>
            <div class="jibie">
              <span>一级药品分类</span>
              <select @change="changeTwoItems($event)">
                <option style="display:none"></option>
                <option
                  selected
                  v-for="(item, index) in drugItems"
                  :key="index"
                >{{item.category_name}}</option>
              </select>
            </div>
            <div class="jibie">
              <span>二级药品分类</span>
              <select @change="changeThreeItems($event)">
                <option style="display:none"></option>
                <option v-for="(item, index) in towDrugItems" :key="index">{{item.category_name}}</option>
              </select>
            </div>
            <div class="jibie">
              <span>三级药品分类</span>
              <select ref="select">
                <option style="display:none"></option>
                <option v-for="(item, index) in threeDrugItems" :key="index">{{item.goods_name}}</option>
              </select>
            </div>
          </div>
          <div class="zhuji">
            <i class="el-icon-search"></i>
            <input type="text" placeholder="请输入助记码" />
          </div>
          <div class="name">
            <div>
              <el-radio v-model="radio" label="1">
                <span>x1</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="2">
                <span>x2</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="3">
                <span>x3</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="4">
                <span>x4</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="5">
                <span>x5</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="6">
                <span>x6</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="7">
                <span>x7</span>
              </el-radio>
            </div>
          </div>
        </div>
        <button @click="sendAddGood">确认添加</button>
      </div>
      <!-- 签名功能 -->
      <div v-show="showGraph" class="autograph">
        <div class="sel">
          <span>医生签名</span>
          <img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('autograph')" />
        </div>
        <div class="content">
          <Autograph @getImgUrl="getImgUrl" />
        </div>
      </div>
      <!-- 确认处方单无误 -->
      <div v-show="isErrorLess" class="isErrorLess">
        <div class="sel">
          <span>提示</span>
          <img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('autograph')" />
        </div>
        <div class="content">确认处方单信息无误?</div>
        <button @click="close('isErrorLess')">否</button>
        <button @click="sendPharmacistExamine">是</button>
      </div>
    </div>
  </div>
</template>

<script>
import Autograph from "@/components/autograph";

// 获取药品列表
import { getAllCate } from "@/request/personnel";
// 获取药品详细信息
import { getCateIndex } from "@/request/personnel";
// 获取处方单详情
import { getInfo } from "@/request/personnel";
// 发送至药师审核
import { inquiryAdd } from "@/request/personnel";

export default {
  props: ["pCode"],
  components: { Autograph },
  data() {
    return {
      isShow: false,
      addGoodDialog: false,
      autograph: false,
      isErrorLess: false,
      drugItems: "", // 药品各级分类
      towDrugItems: {},
      threeDrugItems: {},
      imgUrl: "", // 签名图片URl
      isAutograph: false, // 是否签名
      showGraph: false, // 签名弹框
      addGoods: [], // 添加的药品列表, 即药品存储对象
      num: 1,
      radio: 0,
      diagResult: "",
      disInfo: "" // 处方详细信息
    };
  },
  created() {
    getAllCate().then(res => {
      this.drugItems = res.data;
	  this.drugItems["25"]={category_name:"全部"}
    });
    getInfo({ token: this.$token, p_code: this.pCode }).then(res => {
      this.disInfo = res.data;
      this.diagResult = this.disInfo.inquiry_info.diagnosis; // 诊断结果储存下来
      this.addGoods = this.disInfo.goods_list; // 药品对象
    });
  },
  methods: {
    close(e) {
      this[e] = false;
      this.isShow = false;
      if (e == "autograph") {
        this.showGraph = false;
      }
    },
    open(e) {
      this[e] = true;
      this.isShow = true;
    },
    handleChange(num) {
      // 药品数量变化时触发
      this.num = num;
    },
    getImgUrl(imgUrl) {
      // 获取签名图片并保存
      this.imgUrl = imgUrl;
      this.isShow = false;
      this.showGraph = false;
      this.isAutograph = true;
    },
    sendAddGood() {
      // 医生添加的商品
      // 从第三级数组中筛选出要选择的药品
      let result = this.threeDrugItems.find(
        item => item.goods_name === this.$refs.select.value
      );
      let flag = this.addGoods.some(
        item => item.goods_name === result.goods_name
      );
      // 阻止重复添加同一商品
      if (flag) {
        this.$message({
          type: "error",
          message: "商品已经存在"
        });
      } else {
        this.addGoods.push({
          goods_name: result.goods_name,
          goods_img: result.goods_img,
          num: +this.radio,
          yongfa: "",
          yongliang: "",
          goods_id: result.id
        });
        this.num = this.radio;
        // this.isAddDrug = true;
      }
      this.isShow = false;
      this.addGoodDialog = false;
    },
    cancelGood(good) {
      // 取消添加商品
      let index = this.addGoods.indexOf(good);
      if (index != -1) this.addGoods.splice(index, index + 1);
    },
    changeTwoItems(e) {
      let result = Object.values(this.drugItems).filter(
        item => item.category_name === e.target.value
      );
      this.towDrugItems = result[0].childData;
      this.threeDrugItems = {};
    },
    changeThreeItems(e) {
      let result = Object.values(this.towDrugItems).filter(
        item => item.category_name === e.target.value
      );
      // 直接获取药品详细信息
      getCateIndex({
        category_id: result[0].id,
        shop_id: localStorage.getItem("lzyyShopId")
      }).then(res => {
        this.threeDrugItems = res.data.data;
      });
    },
    sendPharmacistExamine() {

      if(this.imgUrl == '') {
        this.$message({
          type:"error",
          message: "请签名后再提交"
        })
        return;
      }
      // 发送至药师审核
      let data = {
        token: this.$token,
        inquiry_id: this.disInfo.inquiry_info.inquiry_id,
        goods_list_json: this.addGoods,
        inquiry_patient_json: {
          ...this.disInfo.inquiry_info
        },
        audit_sign: this.imgUrl
      };
      console.log(data)
      inquiryAdd(data).then(res => {
        console.log(res)
        if (res.code != 0) {
          this.$message({
            type: "success",
            message: "发送成功"
          });
        }
      });
      this.isShow = false;
      this.isErrorLess = false;

      // 跳转
      this.$emit("changeState", 1);
    }
  }
};
</script>

<style lang="less" scoped>
.modifyPre {
  width: 1200px;
  margin: 0 auto;
  font-size: 18px;
  .title {
    font-size: 20px;
    margin: 40px 0px 20px 30px;
  }
  .info .content {
    height: 176px;
    background: #fbfbfb;
    border: 1px solid #f5f5f5;
    padding: 10px;
    padding-left: 20px;
    table {
      width: 50%;
      tr {
        span:nth-of-type(1) {
          display: inline-block;
          width: 80px;
          margin-right: 20px;
          text-align-last: justify;
        }
        height: 38px;
      }
    }
  }
  .result {
    .content {
      height: 148px;
      background: #fbfbfb;
      border: 1px solid #f5f5f5;
      textarea {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
        resize: none;
        border: none;
        background: #fbfbfb;
        font-size: 20px;
      }
    }
  }
}
hr {
  border: none;
  width: 1060px;
  height: 1px;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 5px;
  background: rgba(234, 234, 234, 1);
}

.addDrug .drugInfo {
  width: 1200px;
  display: flex;
  height: 65px;
  background: #fbfbfb;
  line-height: 65px;
  margin-bottom: 16px;
  div:nth-of-type(1) {
    width: 700px;
    span {
      margin-left: 65px;
    }
  }
  div:nth-of-type(2) {
    width: 400px;
    span {
      margin-left: 230px;
    }
  }
  div:nth-of-type(3) {
    width: 300px;
    span {
      margin-left: 105px;
    }
  }
}
.addDrug .content {
  height: 160px;
  background: #fbfbfb;
  margin-bottom: 16px;
  overflow: hidden;
  div:nth-of-type(1) {
    width: 500px;
    float: left;
    overflow: hidden;
    margin-left: 74px;
    img {
      width: 40px;
      height: 40px;
      float: left;
    }
    p {
      margin-left: 50px;
      width: 326px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    dd:nth-of-type(1) {
      overflow: hidden;
    }
    dd {
      margin-top: 15px;
    }
    input {
      margin-left: 14px;
      font-size: 18px;
    }
  }
  div:nth-of-type(2) {
    width: 400px;
    float: left;
    box-sizing: border-box;
    padding-left: 150px;
  }
  div:nth-of-type(3) {
    width: 200px;
    float: left;
    color: red;
    cursor: pointer;
    margin-top: 16px;
    span {
      margin-left: 80px;
    }
  }
}

.autograph {
  text-align: center;
  p {
    color: #999999;
    height: 146px;
    background: #fbfbfb;
    line-height: 146px;
    text-align: center;
    cursor: pointer;
  }
}
.onlineDrug {
  box-sizing: border-box;
  border: 1px dashed #cccccc;
  padding: 13px 0px;
  text-align: center;
  color: #999;
  img {
    width: 22px;
    height: 22px;
    vertical-align: -3px;
    cursor: pointer;
  }
  span {
    margin-left: 10px;
    cursor: pointer;
  }
}
.send {
  text-align: center;
  margin: 20px 0px;
  button {
    width: 220px;
    height: 50px;
    font-size: 20px;
    background: rgba(56, 238, 193, 1);
    cursor: pointer;
  }
}
.Mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.5);

  .resultDialog {
    width: 600px;
    height: 400px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      textarea {
        width: calc(100% - 16px);
        padding: 8px;
        overflow-y: scroll;
        resize: none;
        outline: none;
        height: 230px;
        font-size: 20px;
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
  .addGoodDialog {
    width: 600px;
    height: 650px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      .jibie {
        height: 35px;
        select {
          height: 27px;
          width: 400px;
          margin-left: 14px;
        }
      }
      .zhuji {
        i {
          margin-top: 10px;
          margin-right: 14px;
        }
      }
      .name {
        border-top: 20px solid #ddd;
        margin-top: 20px;
        padding: 10px;
        span {
          margin-left: 12px;
        }
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
  .autograph {
    width: 600px;
    height: 370px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      margin: 20px 20px 20px;
      height: 240px;
      border: 1px solid #ccc;
    }
  }
  .isErrorLess {
    width: 460px;
    height: 260px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 60px 20px 20px;
      font-size: 24px;
      text-align: center;
    }
    button:nth-of-type(1) {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 150px;
      cursor: pointer;
    }
    button:nth-of-type(2) {
      padding: 5px 25px;
      background: #f00000;
      color: #fff;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
}
.num /deep/ .el-input-number {
  margin-top: 20px;
  width: 120px !important;
  span {
    width: 30px;
    height: 30px;
    background: #eee;
    line-height: 30px;
  }
  .el-input {
    padding: 0px;
    background: #fff;
  }
}
</style>