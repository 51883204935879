// 医生待修改审方

<template>
  <div class="trial" v-if="updatePreList">
    <h3 class="title">修改处方信息</h3>
    <div v-if="updatePreList.length == 0" class="none" >暂无要修改的处方</div>
    <div v-else>
      <div class="wrap" v-for="(item, i) in updatePreList" :key="i">
        <div class="user">{{ item.inquiry_name }}</div>
        <div class="type">
          处理结果 :
          <span>{{ item.status_text }}</span>
          <button @click="changeState(item)">查看</button>
        </div>
        <div class="date">
          申请时间 :
          <span>{{ item.add_time }}</span>
        </div>
      </div>
      <div class="pagination" v-if="showPage">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="15"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 获取自己添加的问诊处方单列表
import { getPrescriptionList } from "@/request/personnel";

export default {
  data() {
    return {
      updatePreList: [],
      total: 0,
      showPage: false
    };
  },
  created() {
    this.getPagePrescriptionList(1);
  },
  methods: {
    getPagePrescriptionList(n) {
      getPrescriptionList({
        token: this.$token,
        status_str: "0,1,3", // 包括待审核、医生已签字、药师已拒绝
        page: n
      }).then(res => {
        if(res.code !== 1) return;
        this.total = res.data.total
        this.showPage = res.data.last_page > 1
        this.updatePreList = res.data.data
      });
    },
    changeState(item) {
      this.$emit("changeState", 2, item.p_code);
    },
    handleCurrentChange(n) {
      this.getPagePrescriptionList(n);
    }
  }
};
</script>

<style lang="less" scoped>
.trial {
  width: 1200px;
  height: 600px;
  position: relative;
  margin: 0 auto 100px;
}
.trial .title {
  font-size: 22px;
  font-weight: 400;
  margin-top: 50px;
  margin-bottom: 35px;
}

.trial .wrap {
  width: 1200px;
  box-sizing: border-box;
  padding-left: 15px;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-left: 0px;
  background: #fbfbfb;
  border: 1px solid #f5f5f5;
  margin-bottom: 20px;
  button {
    width: 60px;
    height: 25px;
    outline: 0;
    cursor: pointer;
    float: right;
    border-radius: 5px;
    margin-right: 35px;
  }
}
.trial .wrap div {
  margin-top: 8px;
  color: #656565;
}
.trial .wrap div.user {
  color: #71c0d6;
  font-size: 20px;
  margin-bottom: 10px;
}
.pagination /deep/ {
  .el-pagination {
    position: absolute;
    bottom: -10px;
    width: 100%;
    text-align: center;
  }
}
.none {
  line-height: 500px;
  font-size: 28px;
  text-align: center;
}
</style>