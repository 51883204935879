<template>
  <div class="video">
    <div id="remotestream"></div>
    <div id="localstream"></div>
  </div>
</template>

<script>
import * as QNRTC from "pili-rtc-web";
import { getRoomToken } from "@/request/user";
export default {
  props: ["inquiryInfo"],
  data() {
    return {};
  },
  created() {
    this.inquiry_doctor_id = this.inquiryInfo.inquiry_doctor_id;
    this.inquiry_id = this.inquiryInfo.id;
  },
  mounted() {
    getRoomToken({
      token: this.$token,
      inquiry_id: this.inquiry_id
    }).then(res => {
      if (res.code == 1) {
        this.ROOMTOKEN = res.data.room_token;
        this.start();
      }
    });
  },
  destroyed() {
    let that = this;
    async function unRoom() {
      await that.myRoom.unpublish();
      await that.myRoom.unsubscribe("target-userid");
    }
    unRoom();
  },
  methods: {
    start() {
      const ROOMTOKEN = this.ROOMTOKEN;
      let that = this;
      joinRoom();
      async function joinRoom() {
        // 初始化一个房间 Session 对象, 这里使用 Stream 模式
        const myRoom = new QNRTC.StreamModeSession();
        that.myRoom = myRoom;
        // 这里替换成刚刚生成的 RoomToken
        await myRoom.joinRoomWithToken(ROOMTOKEN);

        await publish(myRoom);
        // 在这里添加
        autoSubscribe(myRoom);
        
      }

      async function publish(myRoom) {
        // 调用采集模块采集本地的音频和视频数据，返回一个包含这些数据的 Stream 对象
        const localStream = await QNRTC.deviceManager.getLocalStream({
          audio: { enabled: true },
          video: { enabled: true }
        });
        // 将刚刚的 Stream 对象发布到房间中
        await myRoom.publish(localStream);
        console.log("publish success!");
        const localElement = document.getElementById("localstream");
        // 调用 Stream 对象的 play 方法在这个元素下播放媒体流，其中第二个参数代表 静音播放
        localStream.play(localElement, true);
      }

      // 这里的参数 myRoom 是指刚刚加入房间时初始化的 Session 对象, 同上
      // userId 是指订阅对象的用户名
      async function subscribe(myRoom, userId) {
        console.log("远端流");

        // 调用订阅方法发起订阅，成功会返回一个 Stream 对象，这就是远端的流了
        const remoteStream = await myRoom.subscribe(userId);
        // 选择页面上的一个元素作为父元素，播放远端的流
        const remoteElement = document.getElementById("remotestream");
        remoteStream.play(remoteElement);
      }

      // 这里的参数 myRoom 是指刚刚加入房间时初始化的 Session 对象, 同上
      function autoSubscribe(myRoom) {
        const users = myRoom.users;
        console.log("room current users", users);
        // 遍历房间当前所有用户
        for (const user of users) {
          // 如果存在一个用户，用户名不是自己并且已经发布
          // 就可以发起订阅了
          if (user.userId !== myRoom.userId && user.published) {
            // 调用我们刚刚编写的 subscribe 方法
            // 注意这里我们没有使用 async/await，而是使用了 Promise，大家可以思考一下为什么
            subscribe(myRoom, user.userId)
              .then(() => console.log("subscribe success!"))
              .catch(e => console.error("subscribe error", e));
          }
        }

        // 接下来我们需要处理第二种情况，也就是监听事件来感知房间内有用户发布了
        myRoom.on("user-publish", user => {
          console.log("user", user.userId, "is published!");
          subscribe(myRoom, user.userId)
            .then(() => console.log("subscribe success!"))
            .catch(e => console.error("subscribe error", e));
        });

        // 就是这样，就像监听 DOM 事件一样通过 on 方法监听相应的事件并给出处理函数即可
        // 当房间内有其他用户发布时就会触发 "user-publish" 然后被我们订阅
      }
    }
  }
};
</script>

<style lang="less" scoped>
.video {
  position: absolute;
}
#remotestream {
  position: fixed;
  z-index: 0;
  height: calc(100% - 55px);
  width: 62.5%;
  background: #000;
}
#localstream {
  position: fixed;
  right: 37.5%;
  width: 200px;
  height: 150px;
  background: #000;
  z-index: 1;
}
</style>