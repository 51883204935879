// 右边栏
<template>
  <div class="rightCot">
    <div class="topCot">
      <!-- 患者信息 -->
      <div class="patInfo">
        <div class="title">
          <span class="shu"></span>
          <span>患者信息</span>
          <div class="fr">
            <span @click="open('selDialog')">选择</span>
            <span @click="open('editDialog')">编辑</span>
          </div>
        </div>

        <div class="content">
          <el-row>
            <el-col :span="12">
              <span class="space">姓名</span>
              <span>{{ inquiryInfo.realname? inquiryInfo.realname: '无' }}</span>
            </el-col>
            <el-col :span="12">
              <span class="space">性别</span>
              <span>{{ inquiryInfo.sex === 0 ? "保密" : inquiryInfo.sex === 1 ? '男' : '女' }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span class="space">年龄</span>
              <span>{{ inquiryInfo.age ? inquiryInfo.age: '无' }}</span>
            </el-col>
            <el-col :span="12">
              <span class="space">联系电话</span>
              <span>{{ inquiryInfo.mobile ? inquiryInfo.mobile: '无' }}</span>
            </el-col>
          </el-row>
          <el-row>
            <span class="space">过敏史</span>
            <span>{{ inquiryInfo.allergy ? inquiryInfo.allergy: '无'}}</span>
          </el-row>
          <el-row>
            <span class="space">遗传史</span>
            <span>{{ inquiryInfo.genetic_disease ? inquiryInfo.genetic_disease: '无'}}</span>
          </el-row>
        </div>
      </div>
      <!-- 添加药品 -->
      <div class="drugs">
        <div class="title">
          <span class="shu"></span>
          <span>添加药品</span>
          <div class="fr">
            <span @click="open('addGoodDialog')">添加药品</span>
          </div>
        </div>
        <div v-if="isAddDrug" class="drugInfo">
          <el-row :gutter="24" v-for="(item, index) in addGoods" :key="index">
            <el-col :span="22">
              <div class="grid-content bg-purple left">
                <!-- 关闭图片 -->
                <img src="@/assets/image/Popup_Close_icon@2x.png" @click="cancelGood(item)" alt />
                <!-- 名称 -->
                <div class="name">
                  <img :src="item.goods_img" alt />
                  <span>{{ item.goods_name }}</span>
                  <el-input-number
                    class="number"
                    size="mini"
                    :min="1"
                    :max="100"
                    v-model="item.num"
                    @change="handleChange"
                    label="描述文字"
                  ></el-input-number>
                </div>
                <!-- 用法 -->
                <div class="usage">
                  <span>用法</span>
                  <span class="explain">
                    <input type="text" v-model="item.yongfa" />
                  </span>
                </div>
                <!-- 用量 -->
                <div class="Consumption">
                  <span>用量</span>
                  <span class="explain">
                    <input type="text" v-model="item.yongliang" />
                  </span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else class="no_add_drug">
          <span>尚未添加药品</span>
        </div>
      </div>
      <!-- 诊断结果 -->
      <div class="result">
        <div class="title">
          <span class="shu"></span>
          <span>诊断结果</span>
          <div class="fr">
            <span @click="open('resultDialog')">编辑</span>
          </div>
        </div>
        <div class="content">
          <textarea v-model="inquiryInfo.diagnosis" cols="20" rows="5"></textarea>
        </div>
      </div>
    </div>
    <div class="botCot">
      <div v-if="showGraph" @click="clickAutograph">
        <div class="graph">
          <span>点击签名</span>
        </div>
      </div>
      <div v-else>
        <div class="mask" @click="clearAutograph">
          <img :src="imgUrl" alt />
          <span>点击清除签名</span>
        </div>
      </div>
      <button @click="open('isErrorLess')">发送至药师审核</button>
    </div>
    <div v-show="isShow" class="Mask">
      <!-- 选择弹出框 -->
      <div v-show="selDialog" class="selDialog">
        <div class="sel">
          <span>选择患者档案</span>
          <img
            src="@/assets/image/Interrogation_Drugs_delete@2x.png"
            @click="close('selDialog')"
            alt
          />
        </div>
        <div class="content">
          <div class="option" v-for="(v, i) in patientList" :key="i">
            <el-radio v-model="archives" :label="i + 1">{{ v.realname }}</el-radio>
            <span class="time">{{ v.add_time.split(" ")[0] }}</span>
          </div>
        </div>
        <button @click="savaPatient()">保存</button>
      </div>
      <!-- 编辑弹出框 -->
      <div v-show="editDialog" class="editDialog">
        <div class="sel">
          <span>选择患者信息</span>
          <img
            src="@/assets/image/Interrogation_Drugs_delete@2x.png"
            @click="close('editDialog')"
            alt
          />
        </div>
        <div class="content">
          <table>
            <tr>
              <td>
                <span>姓名</span>
                <input
                  type="text"
                  :placeholder="inquiryInfo.realname"
                  v-model="inquiryInfo.realname"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>性别</span>
                <input
                  type="radio"
                  name="sex"
                  :checked="inquiryInfo.sex === 1"
                  @click="inquiryInfo.sex = 1"
                /> 男
                <input
                  type="radio"
                  name="sex"
                  :checked="inquiryInfo.sex === 2"
                  @click="inquiryInfo.sex = 2"
                /> 女
              </td>
            </tr>
            <tr>
              <td>
                <span>年龄</span>
                <input type="text" :placeholder="inquiryInfo.age" v-model="inquiryInfo.age" />
              </td>
            </tr>
            <tr>
              <td>
                <span>联系电话</span>
                <input type="text" :placeholder="inquiryInfo.mobile" v-model="inquiryInfo.mobile" />
              </td>
            </tr>
            <tr>
              <td>
                <span>过敏史</span>
                <input type="text" :placeholder="inquiryInfo.allergy" v-model="inquiryInfo.allergy" />
              </td>
            </tr>
            <tr>
              <td>
                <span>遗传史</span>
                <input
                  type="text"
                  :placeholder="inquiryInfo.genetic_disease"
                  v-model="inquiryInfo.genetic_disease"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>身份证</span>
                <input type="text" :placeholder="inquiryInfo.idcard" v-model="inquiryInfo.idcard" />
              </td>
            </tr>
          </table>
        </div>
        <button @click="savaPatientInfo">保存</button>
      </div>
      <!-- 诊断结果编辑 -->
      <div v-show="resultDialog" class="resultDialog">
        <div class="sel">
          <span>诊断结果</span>
          <img
            src="@/assets/image/Interrogation_Drugs_delete@2x.png"
            @click="close('resultDialog')"
          />
        </div>
        <div class="content">
          <textarea v-model="inquiryInfo.diagnosis"></textarea>
        </div>
        <button @click="savaDiagnosisResult">保存</button>
      </div>
      <!-- 添加商品 -->
      <div v-show="addGoodDialog" class="addGoodDialog">
        <div class="sel">
          <span>添加商品</span>
          <img
            src="@/assets/image/Interrogation_Drugs_delete@2x.png"
            @click="close('addGoodDialog')"
          />
        </div>
        <div class="content" v-if="drugItems">
          <div>
            <div class="jibie">
              <span>一级药品分类</span>
              <select @change="changeTwoItems($event)">
                <option style="display:none"></option>
                <option
                  selected
                  v-for="(item, index) in drugItems"
                  :key="index"
                >{{item.category_name}}</option>
              </select>
            </div>
            <div class="jibie">
              <span>二级药品分类</span>
              <select @change="changeThreeItems($event)">
                <option style="display:none"></option>
                <option v-for="(item, index) in towDrugItems" :key="index">{{item.category_name}}</option>
              </select>
            </div>
            <div class="jibie">
              <span>三级药品分类</span>
              <select ref="select">
                <option style="display:none"></option>
                <option v-for="(item, index) in threeDrugItems" :key="index">{{item.goods_name}}</option>
              </select>
            </div>
          </div>
          <div class="zhuji">
            <i class="el-icon-search"></i>
            <input type="text" placeholder="请输入助记码" />
          </div>
          <div class="name">
            <div>
              <el-radio v-model="radio" label="1">
                <span>x1 （袋 / 盒）</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="2">
                <span>x2 （袋 / 盒）</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="3">
                <span>x3 （袋 / 盒）</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="4">
                <span>x4 （袋 / 盒）</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="5">
                <span>x5 （袋 / 盒）</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="6">
                <span>x6 （袋 / 盒）</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="7">
                <span>x7 （袋 / 盒）</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="8">
                <span>x8 （袋 / 盒）</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="9">
                <span>x9 （袋 / 盒）</span>
              </el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="10">
                <span>x10 （袋 / 盒）</span>
              </el-radio>
            </div>
          </div>
        </div>
        <button @click="sendAddGood">确认添加</button>
      </div>
      <!-- 签名功能 -->
      <div v-show="autograph" class="autograph">
        <div class="sel">
          <span>医生签名</span>
          <img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('autograph')" />
        </div>
        <div class="content">
          <Autograph @getImgUrl="getImgUrl" />
        </div>
      </div>
      <!-- 确认处方单无误 -->
      <div v-show="isErrorLess" class="isErrorLess">
        <div class="sel">
          <span>提示</span>
          <img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('autograph')" />
        </div>
        <div class="content">确认处方单信息无误?</div>
        <button @click="close('isErrorLess')">否</button>
        <button @click="sendPharmacistExamine">是</button>
      </div>
    </div>
  </div>
</template>

<script>
import Autograph from "@/components/autograph";

// 获取药品列表
import { getAllCate } from "@/request/personnel";
// 获取药品详细信息
import { getCateIndex } from "@/request/personnel";
// 发送至药师审核
import { inquiryAdd } from "@/request/personnel";

export default {
  props: ["inquiryInfo", "patientList"],
  components: {
    Autograph
  },
  data() {
    return {
      isShow: false, //遮罩层显示
      selDialog: false,
      editDialog: false,
      resultDialog: false,
      addGoodDialog: false,
      showGraph: true,
      isErrorLess: false,
      radio: 0,
      archives: 1,
      drugItems: "", // 药品各级分类
      towDrugItems: {},
      threeDrugItems: {},
      imgUrl: "", // 签名图片URl
      autograph: false,
      isAddDrug: false, // 是否添加药品
      addGoods: [], // 医生添加的商品
      selectGood: "", // 当前选择的商品
      errorLess: false // 问诊处方单是否有误
    };
  },
  created() {
    getAllCate().then(res => {
      this.drugItems = res.data;
	  this.drugItems["25"]={category_name:"全部"}
    });
  },
  methods: {
    close(e) {
      this[e] = false;
      this.isShow = false;
    },
    open(e) {
      console.log(this.patientList);
      this[e] = true;
      this.isShow = true;
      if (e === "autograph") {
        this.autograph = false;
      }
    },
    handleChange() {},
    changeTwoItems(e) {
      let result = Object.values(this.drugItems).filter(
        item => item.category_name === e.target.value
      );
      this.towDrugItems = result[0].childData;
      this.threeDrugItems = {};
    },
    changeThreeItems(e) {
      let result = Object.values(this.towDrugItems).filter(
        item => item.category_name === e.target.value
      );
      // 直接获取药品详细信息
      getCateIndex({
        category_id: result[0].id,
        shop_id: localStorage.getItem("lzyyShopId")
      }).then(res => {
        this.threeDrugItems = res.data.data;
      });
      // this.threeDrugItems = result[0].childData;
    },
    getImgUrl(imgUrl) {
      // 获取签名图片并保存
      this.imgUrl = imgUrl;
      this.showGraph = false;
      this.isShow = false;
      this.autograph = false;
    },
    clickAutograph() {
      // 签名
      this.isShow = true;
      this.autograph = true;
    },
    clearAutograph() {
      // 清除签名
      this.imgUrl = "";
      this.showGraph = true;
    },
    savaPatientInfo() {
      // 保存患者信息
      this.isShow = false;
      this.editDialog = false;
    },
    savaPatient() {
      // 保存患者档案
      if (this.patientList.length == 0) return;
      let v = this.patientList.slice(this.archives - 1, this.archives)[0];
      // this.inquiryInfo.age = v.age;
      this.inquiryInfo.sex = v.sex;
      this.inquiryInfo.allergy = v.allergy;
      this.inquiryInfo.genetic_disease = v.genetic_disease;
      this.disease_desc = v.disease_desc;
      this.inquiryInfo.mobile = v.mobile;
      this.inquiryInfo.realname = v.realname;
      this.inquiryInfo.height = v.height;
      this.inquiryInfo.add_time = v.add_time;
      this.inquiryInfo.birthday = v.birthday;
      this.isShow = false;
      this.selDialog = false;
    },
    savaDiagnosisResult() {
      // 保存诊断结果
      this.isShow = false;
      this.resultDialog = false;
    },
    sendAddGood() {
      // 医生添加的商品
      // 从第三级数组中筛选出要选择的药品
      let result = this.threeDrugItems.find(
        item => item.goods_name === this.$refs.select.value
      );
      let flag = this.addGoods.some(
        item => item.goods_name === result.goods_name
      );
      if (flag) {
        this.$message({
          type: "error",
          message: "商品已经存在"
        });
      } else {
        this.addGoods.push({
          goods_name: result.goods_name,
          goods_img: result.goods_img,
          num: +this.radio,
          yongfa: "",
          yongliang: "",
          goods_id: result.id
        });

        this.isAddDrug = true;
      }
      this.isShow = false;
      this.addGoodDialog = false;
    },
    cancelGood(good) {
      // 取消添加商品
      let index = this.addGoods.indexOf(good);
      if (index != -1) this.addGoods.splice(index, index + 1);
    },
    sendPharmacistExamine() {
      // 发送至药师审核
      let data = {
        token: this.$token,
        inquiry_id: this.inquiryInfo.id,
        goods_list_json: this.addGoods,
        inquiry_patient_json: {
          ...this.inquiryInfo
        },
        audit_sign: this.imgUrl
      };
      inquiryAdd(data).then(res => {
        if (res.code != 0) {
          this.$message({
            type: "success",
            message: "发送成功"
          });
        }
      });
      this.isShow = false;
      this.isErrorLess = false;
    }
  }
};
</script>

<style  lang="less" scoped>
.rightCot {
  height: 100%;
  width: 37.5%;
  position: absolute;
  min-width: 450px;
  right: 0;
}
.topCot {
  overflow: hidden;
  .title {
    background: rgba(230, 255, 249, 1);
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
    .shu {
      display: inline-block;
      width: 4px;
      height: 20px;
      background-color: #38eec1;
      float: left;
      margin-top: 6px;
      margin-right: 8px;
    }
    .fr {
      font-size: 16px;
      color: #38eec1;
      span {
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
  .patInfo {
    margin-bottom: 15px;
    .content {
      margin-left: 15px;
      margin-top: 10px;
      .space {
        display: inline-block;
        width: 70px;
        text-align-last: justify;
        margin-right: 10px;
      }
    }
  }
  .result {
    .content {
      textarea {
        width: calc(100% - 30px);
        border: none;
        padding: 15px;
        font-size: 18px;
      }
    }
  }
  .drugs {
    margin-bottom: 15px;
    .no_add_drug {
      line-height: 240px;
      text-align: center;
      color: #666;
    }
    .drugInfo {
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 14px;
      height: 240px;
      > .el-row {
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        margin-left: 5px !important;
        .grid-content.bg-purple {
          > img {
            position: absolute;
            right: 30px;
            top: 6px;
            cursor: pointer;
          }
        }
      }
      .el-input-number {
        float: right;
      }
      .explain {
        margin: 0 12px;
      }
      .el-input {
        display: inline-block;
      }
      .title {
        margin-bottom: 20px;
      }
      .grid-content.bg-purple.left > div {
        margin-top: 10px;
        margin-left: 8px;
      }
      .drugInfo .el-row:nth-child(1) {
        line-height: 40px;
      }
      .grid-content.bg-purple.num span {
        margin-left: 50px;
      }
      .grid-content.bg-purple.del {
        color: red;
        cursor: pointer;
      }
      .name {
        > span {
          width: 60%;
          display: inline-block;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        img {
          vertical-align: -10px;
          margin-right: 15px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
.botCot {
  width: 100%;
  border-top: 1px solid #38eec1;
  height: 140px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  > div {
    width: 210px;
    height: 105px;
    position: absolute;
    top: 30px;
    left: 10px;
    .mask {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 210px;
      height: 105px;
      background: rgba(0, 0, 0, 0.5);
      line-height: 110px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      span {
        position: absolute;
        top: 0px;
        color: #fff;
        left: 50px;
      }
    }
    .graph {
      box-sizing: border-box;
      border: 2px solid #ccc;
      height: 100%;
      text-align: center;
      cursor: pointer;
      span {
        display: inline-block;
        margin-top: 40px;
      }
    }
  }
  button {
    padding: 10px 35px;
    background: #38eec1;
    border-radius: 12px;
    position: absolute;
    top: 100px;
    margin-left: 240px;
    cursor: pointer;
  }
}
.Mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.5);
  .selDialog {
    width: 600px;
    height: 400px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      .option {
        display: inline-block;
        width: 40%;
        line-height: 35px;
        margin-left: 20px;
        .el-radio {
          margin-left: 20px;
          width: 55px;
        }
        span {
          color: #666;
        }
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
  .editDialog {
    width: 600px;
    height: 400px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      table {
        width: 100%;
        tr {
          span {
            display: inline-block;
            width: 70px;
            margin-right: 10px;
            text-align-last: justify;
          }
          height: 38px;
        }
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
  .resultDialog {
    width: 600px;
    height: 400px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      textarea {
        width: calc(100% - 16px);
        padding: 8px;
        overflow-y: scroll;
        resize: none;
        outline: none;
        height: 230px;
        font-size: 20px;
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
  .addGoodDialog {
    width: 600px;
    height: 650px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      .jibie {
        height: 35px;
        select {
          height: 27px;
          width: 400px;
          margin-left: 14px;
        }
      }
      .zhuji {
        i {
          margin-top: 10px;
          margin-right: 14px;
        }
      }
      .name {
        border-top: 20px solid #ddd;
        margin-top: 20px;
        padding: 10px;
        span {
          margin-left: 12px;
        }
      }
    }
    button {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
  .autograph {
    width: 600px;
    height: 370px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      margin: 20px 20px 20px;
      height: 240px;
      border: 1px solid #ccc;
    }
  }
  .isErrorLess {
    width: 460px;
    height: 260px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 60px 20px 20px;
      font-size: 24px;
      text-align: center;
    }
    button:nth-of-type(1) {
      padding: 5px 25px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 150px;
      cursor: pointer;
    }
    button:nth-of-type(2) {
      padding: 5px 25px;
      background: #f00000;
      color: #fff;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      left: 260px;
      cursor: pointer;
    }
  }
}
</style>