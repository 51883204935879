<template>
  <div class="header">
    <p class="fl"> {{ inquiryName }}</p>
    <p class="fr" @click="open" >
      <router-link to> 
        结束问诊
        <i class="el-icon-switch-button"></i>
      </router-link>
    </p>
    <!-- 结束问诊选择 -->
    <div class="Mask" v-show="dialogVisible">
      <div class="dialog">
        <div class="sel">
          <span>提示</span>
          <img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close(false)" alt />
        </div>
        <div class="content">是否结束并退出当前咨询</div>
        <div>
          <button @click="close(false)">否</button>
          <button @click="close(true)">是</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["inquiryName"],
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    close(bool) {
      this.dialogVisible = false;
      if(bool) {
        this.$emit("finishInquiry");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  overflow: hidden;
  width: 100%;
  height: 55px;
  line-height: 55px;
  background: #38eec1;
  min-width: 1200px;
  p {
    color: #fff;
    margin: 0 25px;
  }
  .fr {
    cursor: pointer;
    a {
      color: #fff;
    }
  }
  .fr , .fl {
    font-size: 18px;
  }
}
.Mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.5);
  .dialog {
    width: 500px;
    height: 280px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    div {
      span {
        float: left;
        margin-top: -10px;
      }
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      padding: 40px 20px 20px;
      text-align: center;
      line-height: 150px;
      font-size: 25px;
      font-weight: 500;
    }
    button {
      padding: 5px 40px;
      background: #fff;
      color: red;
      border: 1px solid red;
      border-radius: 12px;
      position: absolute;
      bottom: 20px;
      outline: 0;
      cursor: pointer;
    }
    button:nth-child(1) {
      left: 140px;

    }
    button:nth-child(2) {
      left: 270px;
      background: red;
      color:#fff;

    }
  }
}
</style>